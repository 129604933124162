/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '../../color';
import Typos from '../../typography';

interface Props {
  emoji: string;
  margin?: string;
}

const EmojiField = ({ emoji, margin }: Props) => {
  return (
    <div
      css={css`
        background-color: ${Color.inversed};
        height: 52px;
        border-radius: 8px;
        border: 2px solid ${Color.disabled1};
        ${Typos.Headline};
        padding: 13px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        flex: 1;

        ::placeholder {
          color: ${Color.disabled3};
        }
      `}
    >
      {emoji}
    </div>
  );
};

export default EmojiField;
