/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '../color';
import WebTypos from '../typography/Web';

import ExperienceImg from '../../asset/landing/experience.png';
import { useNavigate } from 'react-router-dom';

const Ending = () => {
  const navigate = useNavigate();

  return (
    <div
      css={css`
        background-color: ${Color.disabled1};
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        justify-content: center;
        gap: 260px;

        @media (max-width: 1320px) {
          flex-direction: column-reverse;
          gap: 0px;
        }
      `}
    >
      <div css={css``}>
        <img
          src={ExperienceImg}
          alt='ExperienceImg'
          css={css`
            width: 620px;
            vertical-align: bottom;

            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        />
      </div>
      <div
        css={css`
          justify-content: center;
          display: flex;
          flex-direction: column;

          @media (max-width: 1320px) {
            padding-top: 40px;
            align-items: center;
          }
        `}
      >
        <p
          css={css`
            ${WebTypos.Headline3};
            margin-bottom: 12px;

            @media (max-width: 1320px) {
              ${WebTypos.Headline5};
              margin-bottom: 4px;
            }
          `}
        >
          내 블로그 랭킹 보러가기
        </p>
        <p
          css={css`
            ${WebTypos.Body3};
            margin-bottom: 20px;

            @media (max-width: 1320px) {
              ${WebTypos.Body7};
              margin-bottom: 16px;
            }
          `}
        >
          블로그와 키워드 순위를 실시간으로 확인해보세요.
        </p>
        <span
          onClick={() => {
            navigate('/rank');
          }}
          css={css`
            align-items: center;
            border-radius: 24px;
            color: ${Color.inversed};
            background-color: ${Color.primary1};
            display: flex;
            border-radius: 30px;
            width: fit-content;
            ${WebTypos.Body2};
            padding: 8px 12px 8px 24px;

            @media (max-width: 1320px) {
              padding: 6px 6px 6px 12px;
              border-radius: 16px;
              ${WebTypos.Body7};
            }
          `}
        >
          랭킹조회 바로가기
          <i className='material-icons'>chevron_right</i>
        </span>
      </div>
    </div>
  );
};
export default Ending;
