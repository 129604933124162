/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typos from '../../../typography';
import Color from '../../../color';

interface Props {
  title: string;
  description?: string;
}

const TutorialText = ({ title, description }: Props) => {
  return (
    <div>
      <p
        css={css`
          text-align: center;
          color: ${Color.default2};
          ${Typos.Subline1}
        `}
      >
        {title}
      </p>
      <p
        css={css`
          text-align: center;
          margin-top: 8px;
          white-space: pre-line;
          color: ${Color.default2};
          ${Typos.Body3}
        `}
      >
        {description}
      </p>
    </div>
  );
};

export default TutorialText;
