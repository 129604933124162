/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../components/layout/Container';
import Header from '../../components/ui/Text/Header/Header';
import Button from '../../components/ui/buttons/Button';
import CardImage from '../../components/ui/card/CardImage';
import CardText from '../../components/ui/card/CardText';

import VerticalSpace from '../../components/layout/VerticalSpace';
import HorizontalSpace from '../../components/layout/HorizontalSpace';
import DescriptionText from '../../components/ui/Text/DescriptionText/DescriptionText';

const ReviewConfirmation = () => {
  const openSuperMembersApp = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=kr.co.mayacrew.supermembers',
      '_blank'
    );
  };
  return (
    <>
      <Container
        footerElement={
          <>
            <div
              css={css`
                height: 50px;
                margin-top: auto;
                margin-bottom: 20px;
              `}
            >
              <DescriptionText
                text={`리뷰 쓰고 할인받는 앱 아시나요?
            슈퍼멤버스를 사용하고 할인을 받아보세요!`}
              />
            </div>
            <Button
              label='리뷰 쓰고 할인 받으러 가기'
              onClick={openSuperMembersApp}
              isRounded
            />
          </>
        }
        footerPadding='0px 24px'
      >
        <div
          css={css`
            flex: 1;
            padding: 0px 24px;
          `}
        >
          <VerticalSpace size={44} />
          <Header
            title='등록이 완료되었어요!'
            description='1일 내로 카카오톡으로 상품을 받을 수 있어요.'
          />
          <VerticalSpace size={16} />
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <CardImage URL='https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/qr%2FcardList%2Fnaver.png?alt=media&token=4da7f6ec-ec16-45f3-98dc-b4c944fa9222' />
            <HorizontalSpace size={24} />
            <CardText
              companyName='네이버 페이'
              benefits='모바일 상품권 1천원권'
              amount='1,000원'
              size='medium'
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ReviewConfirmation;
