/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typos from '../../../typography';
import Color from '../../../color';

interface Props {
  text: string;
}

const DescriptionText = ({ text }: Props) => {
  return (
    <p
      css={css`
        ${Typos.Body3};
        color: ${Color.default1};
        white-space: pre-line;
        text-align: center;
      `}
    >
      {text}
    </p>
  );
};

export default DescriptionText;
