/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import TopImg from '../../asset/landing/top.png';
import Qr from '../../asset/landing/qr.png';
import WebTypos from '../typography/Web';
import useWindowWidth from '../../hooks/useWindowWidth';

const Top = () => {
  const windowWidth = useWindowWidth();

  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;

        @media (max-width: 1320px) {
          flex-direction: column;
          align-items: center;
        }
      `}
    >
      <div>
        <p
          css={css`
            ${WebTypos.Headline};
            margin-bottom: 40px;

            width: max-content;

            @media (max-width: 1320px) {
              ${WebTypos.Headline3};
              margin-bottom: 8px;
              margin-bottom: 80px;
            }
          `}
        >
          블로그 등급 UP, 할인도 UP!
          <br />
          당일 방문 가능한 슈퍼멤버스
        </p>
        {windowWidth > 1320 && (
          <img
            src={Qr}
            alt='Qr'
            css={css`
              width: 360px;
            `}
          />
        )}
      </div>
      <div>
        <img
          src={TopImg}
          alt='TopImg'
          css={css`
            width: 730px;

            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        />
      </div>
    </div>
  );
};

export default Top;
