/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Button from '../../components/ui/buttons/Button';
import Container from '../../components/layout/Container';
import Header from '../../components/ui/Text/Header/Header';
import VerticalSpace from '../../components/layout/VerticalSpace';
import DescriptionText from '../../components/ui/Text/DescriptionText/DescriptionText';
import InvalidStoreIMG from '../../asset/invalidStore.png';

const InvalidStore = () => {
  const openSuperMembersApp = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=kr.co.mayacrew.supermembers',
      '_blank'
    );
  };

  return (
    <Container
      footerElement={
        <>
          <div
            css={css`
              height: 50px;
              margin-top: auto;
              margin-bottom: 20px;
            `}
          >
            <DescriptionText
              text={`리뷰 쓰고 할인받는 앱 아시나요?
            슈퍼멤버스를 사용하고 할인을 받아보세요!`}
            />
          </div>
          <Button
            label='리뷰 쓰고 할인 받으러 가기'
            onClick={openSuperMembersApp}
            isRounded
          />
        </>
      }
      footerPadding='0px 24px'
    >
      <div
        css={css`
          flex: 1;
          padding: 0px 24px;
        `}
      >
        <VerticalSpace size={44} />
        <Header
          title='사용할 수 없는 매장이에요'
          description={`슈퍼멤버스에 등록이 되어있는 매장만
            사용이 가능해요.`}
        />
        <VerticalSpace size={16} />
        <div
          css={css`
            width: 100%;
          `}
        >
          <img
            alt='InvalidStoreIMG'
            src={InvalidStoreIMG}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
      </div>
    </Container>
  );
};

export default InvalidStore;
