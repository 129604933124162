/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Slider from 'react-slick';
import Color from '../color';

interface Props {
  children: React.ReactNode;
  isShowDots?: boolean;
  slideChangeCallback?: (index: number) => void;
}

const CarouselItem = ({ children }: Props) => {
  return (
    <div
      css={css`
        height: 248px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
      `}
    >
      {children}
    </div>
  );
};

const Carousel = ({ children, isShowDots, slideChangeCallback }: Props) => {
  const settings = {
    dots: isShowDots,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    beforeChange: (_current: any, next: any) => {
      if (slideChangeCallback) {
        slideChangeCallback(next);
      }
    },
  };

  return (
    <div
      css={css`
        position: relative;
        height: 248px;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;

        .slick-dots li.slick-active button:before {
          color: ${Color.primary1} !important;
        }

        .slick-dots li button:before {
          opacity: 1 !important;
          color: ${Color.primary2};
        }

        .slick-dots {
          bottom: 8px;
        }
      `}
    >
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

Carousel.CarouselItem = CarouselItem;

export default Carousel;
