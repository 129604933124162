/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import Color from '../color';

const flash = keyframes`
 0% {
    background-color: ${Color.primary_opacity1};
    box-shadow: 32px 0 ${Color.primary_opacity1}, -32px 0 ${Color.primary1};
  }
  50% {
    background-color: ${Color.primary1};
    box-shadow: 32px 0 ${Color.primary_opacity1}, -32px 0 ${Color.primary_opacity1};
  }
  100% {
    background-color: ${Color.primary_opacity1};
    box-shadow: 32px 0 ${Color.primary1}, -32px 0 ${Color.primary_opacity1};
  }
`;

const Loading = () => {
  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 100;
      `}
    >
      <span
        css={css`
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: ${Color.primary1};
          box-shadow: 32px 0 ${Color.primary1}, -32px 0 ${Color.primary1};
          position: relative;
          animation: ${flash} 0.5s ease-out infinite alternate;
        `}
      ></span>
    </div>
  );
};

export default Loading;
