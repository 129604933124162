import { Link } from 'react-router-dom';
import Mayacrew from '../asset/mayacrew.svg';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { pathname } = useLocation();

  if (pathname.includes('/receipt') || pathname.includes('/map')) {
    return null;
  }

  return (
    <footer className='main-footer'>
      <div className='content-wrapper flex'>
        <h4>
          <img src={Mayacrew} alt='mayacrew logo' /> (주) 마야크루
        </h4>
        <div className='flex'>
          <h4>
            <Link to='/terms'>이용약관</Link>
          </h4>
          <h4>
            <Link to='/privacy'>개인정보처리방침</Link>
          </h4>
        </div>
      </div>
      <div className='content-wrapper flex left'>
        <div>
          <span>대표</span> 오준호
        </div>
        <div>
          <span>사업자 등록번호</span> 875-81-00475
        </div>
        <div>
          <span>주소</span> 서울시 마포구 와우산로21길 20-11 2층
        </div>
      </div>
    </footer>
  );
};

export default Footer;
