/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Step3Icon from '../../asset/landing/Step3.png';
import Step3Img from '../../asset/landing/step3Img.png';
import useWindowWidth from '../../hooks/useWindowWidth';
import WebTypos from '../typography/Web';

const Step3 = () => {
  const windowWidth = useWindowWidth();
  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 150px;

        @media (max-width: 1320px) {
          flex-direction: column;
          gap: 0px;
        }
      `}
    >
      <div
        css={css`
          width: 400px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-left: 110px;

          @media (max-width: 1320px) {
            gap: 12px;
            margin-left: 0px;
            text-align: center;
            width: max-content;
          }
        `}
      >
        {windowWidth > 1320 && (
          <img
            src={Step3Icon}
            alt='Step3Icon'
            css={css`
              height: 72px;
              width: fit-content;
            `}
          />
        )}

        <p
          css={css`
            ${WebTypos.Headline2};
            @media (max-width: 1320px) {
              ${WebTypos.Headline4};
            }
          `}
        >
          영수증 리뷰만 남겨도
          <br /> 10% 할인!
        </p>
        <p
          css={css`
            ${WebTypos.Body3};
            @media (max-width: 1320px) {
              ${WebTypos.Body6};
              margin-bottom: 40px;
            }
          `}
        >
          슈퍼멤버스는 포스팅 리뷰가 아닌 영수증 리뷰만 <br /> 남기더라도 10%
          할인을 받을 수 있습니다.
        </p>
      </div>
      <div css={css``}>
        <img
          src={Step3Img}
          alt='Step3Img'
          css={css`
            width: 620px;
            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        />
      </div>
    </div>
  );
};

export default Step3;
