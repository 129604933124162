/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typos from '../../typography';
import Color from '../../color';

type ButtonProps = {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  margin?: string;
  isRounded?: boolean;
};

const Button = ({
  label,
  onClick,
  disabled,
  margin,
  isRounded,
}: ButtonProps) => {
  const buttonStyle = css`
    background-color: ${Color.primary1};
    border: none;
    width: 100%;
    cursor: ${disabled ? 'default' : 'pointer'};
    margin: ${margin};
    padding: 14px 16px;
    border-radius: ${isRounded ? '8px' : ''};
    ${Typos.Body2}
    color: ${Color.inversed};

    &:disabled {
      background-color: ${Color.disabled2};
    }
  `;

  return (
    <button css={buttonStyle} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};

export default Button;
