// import React, { Component } from 'react';
// import axios from 'axios';
// import * as htmlToImage from 'html-to-image';
// import { Line } from 'react-chartjs-2';

// import Chart1d from '../asset/chart1d.png';
// import Chart3d from '../asset/chart3d.png';
// import './Rank.scss';

// import SearchIcon from '@mui/icons-material/Search';

// const chartColorArr = [
//   '#DD2333',
//   '#E34956',
//   '#EB717B',
//   '#F29BA2',
//   '#FAC8CC',
//   '#ECEFF1',
// ];
// interface State {
//   account: string;
//   alert: string;
//   disabled: boolean;
//   data: any;
//   currentSort: string[];
//   showAllKeyword: boolean;
// }
// class Rank extends Component<any, State> {
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       account: '',
//       alert: '',
//       disabled: false,
//       data: null,
//       currentSort: ['mmqccnt', 'down'],
//       showAllKeyword: false,
//     };
//     this.handleChange = this.handleChange.bind(this);
//     this.handleSearch = this.handleSearch.bind(this);
//     this.handleSave = this.handleSave.bind(this);
//     this.result = React.createRef();
//   }
//   private result: React.RefObject<HTMLDivElement> | null | undefined;
//   public render() {
//     const { params } = this.props.match;
//     const { account, alert, disabled, data, currentSort, showAllKeyword } =
//       this.state;
//     const circumference = 2 * Math.PI * 62;
//     let rotate = -90;
//     let hideChart = params.show != 'show';

//     const histories =
//       data !== null &&
//       ['rank', 'adFee'].map((key, idx) => {
//         return (
//           <div className='chart-container'>
//             <div className='underline'>
//               {key === 'rank' ? '주간 블로그 순위' : '1회 예상 광고비용 추이'}
//             </div>
//             <div className='chart'>
//               <div className='item'>
//                 <div className='rank'>
//                   {`${
//                     data[key].toLocaleString() + (key === 'rank' ? '위' : '원')
//                   }`}
//                 </div>
//                 <div className='caption'>매주 월요일 기준</div>
//               </div>
//               <div className='blur-container'>
//                 <div className='line-chart'>
//                   {hideChart ? (
//                     <a
//                       target='_blank'
//                       href='https://rank.supermembers.co.kr/app?channel=naver'
//                       rel='noreferrer'
//                     >
//                       <img src={Chart1d} alt='차트' />
//                     </a>
//                   ) : (
//                     <Line
//                       data={{
//                         labels: data.histories.map(
//                           (history: any) =>
//                             history.week.split('-')[1] +
//                             '.' +
//                             history.week.split('-')[2]
//                         ),
//                         datasets: [
//                           {
//                             data: data.histories.map((history: any) =>
//                               Math.round(history[key])
//                             ),
//                             fill: false,
//                             backgroundColor: 'rgb(189, 19, 40)',
//                             borderColor: 'rgb(189, 19, 40, 0.8)',
//                             tension: 0.5,
//                             borderWidth: 2,
//                           },
//                         ],
//                       }}
//                       options={{
//                         hover: {
//                           mode: 'index',
//                           intersect: false,
//                         },
//                         responsive: true,
//                         plugins: {
//                           legend: {
//                             display: false,
//                           },
//                           title: {
//                             display: false,
//                           },
//                         },
//                         scales: {
//                           x: {
//                             grid: {
//                               display: false,
//                             },
//                             time: {
//                               displayFormats: {
//                                 day: 'MM.DD',
//                               },
//                             },
//                             ticks: {
//                               source: 'data',
//                             },
//                           },
//                           y: {
//                             reverse: key === 'rank',
//                             beginAtZero: true,
//                             ticks: {
//                               precision: 0,
//                               callback: function (value) {
//                                 return value
//                                   .toLocaleString()
//                                   .padStart(6, String.fromCharCode(8192));
//                               },
//                             },
//                           },
//                         },
//                       }}
//                     />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       });

//     const legend =
//       data !== null &&
//       Object.keys(data.categories).map((key, idx) => {
//         return (
//           <div
//             className='capsule'
//             style={{ justifyContent: 'space-between' }}
//             key={`legend${idx}`}
//           >
//             <div className='capsule'>
//               <div
//                 className='chip'
//                 style={{ backgroundColor: chartColorArr[idx] }}
//               ></div>
//               <p className='legend'>{key}</p>
//             </div>
//             <p className='legend'>{`${data.categories[key]}%`}</p>
//           </div>
//         );
//       });
//     const pieces =
//       data !== null &&
//       Object.keys(data.categories).map((key, idx) => {
//         const item = (
//           <circle
//             key={`piece${idx}`}
//             r={62}
//             cx={72}
//             cy={72}
//             transform={`rotate(${rotate} 72 72)`}
//             style={{
//               strokeDasharray: `${
//                 (data.categories[key] * circumference) / 100
//               } ${circumference}`,
//               stroke: chartColorArr[idx],
//             }}
//             className='donut-piece'
//           />
//         );
//         rotate += (data.categories[key] * 2 * 180) / 100;
//         return item;
//       });
//     const sortTypes: any = {
//       up: {
//         class: 'sort-up',
//         fn: (a: any, b: any) =>
//           currentSort[0] === 'rank'
//             ? a.ids.indexOf(account) - b.ids.indexOf(account)
//             : a[currentSort[0]] - b[currentSort[0]],
//       },
//       down: {
//         class: 'sort-down',
//         fn: (a: any, b: any) =>
//           currentSort[0] === 'rank'
//             ? b.ids.indexOf(account) - a.ids.indexOf(account)
//             : b[currentSort[0]] - a[currentSort[0]],
//       },
//       default: {
//         class: 'sort',
//         fn: (a: any, b: any) => a,
//       },
//     };
//     const keywords =
//       data !== null &&
//       data.keywords
//         .sort(sortTypes[currentSort[1]].fn)
//         .slice(0, showAllKeyword ? undefined : 5)
//         .map((item: any) => (
//           <tr>
//             <td>{item.name}</td>
//             <td>{(item.mmqccnt || 0).toLocaleString()}</td>
//             <td>{(item.ids || []).indexOf(account) + 1 + '위'}</td>
//           </tr>
//         ));
//     return (
//       <div className='rank content-wrapper flex column'>
//         <div className={`header ${data !== null && 'searched'}`}>
//           <h2>슈퍼멤버스</h2>
//           <h1>인플루언서 랭킹</h1>
//         </div>
//         <div className={`search-input ${data !== null && 'searched'}`}>
//           {/* <i className='material-icons'>search</i> */}
//           <SearchIcon />
//           <input
//             placeholder='네이버 아이디를 검색해보세요.'
//             disabled={disabled}
//             value={account}
//             onKeyUp={this.handleSearch}
//             onChange={this.handleChange}
//           />
//         </div>
//         <p className={`alert ${data !== null && 'searched'}`}>{alert}</p>
//         {data === null && (
//           <div className='info'>
//             <p>
//               네이버 블로그를 운영하고 계신가요?
//               <br />
//               <br />
//               네이버 아이디를 입력하시면,
//               <br /> 슈퍼멤버스 영향력 측정 엔진을 기반으로 분석된
//               <br />
//               블로그 랭킹을 확인할 수 있습니다.
//             </p>
//             <p className='update'>
//               조회된 1회 예상 광고비용을 실제로 받을 수 있다면?
//             </p>
//             <button>
//               <a
//                 href='https://rank.supermembers.co.kr/app?channel=naver'
//                 target='_blank'
//                 rel='noreferrer'
//               >
//                 슈퍼멤버스 앱 다운로드
//                 <span className='mdi mdi-chevron-right'></span>
//               </a>
//             </button>
//           </div>
//         )}
//         {data !== null && (
//           <div className='search-result'>
//             <div className='header'>
//               <img
//                 className='photo'
//                 src={data.photo}
//                 width={40}
//                 height={40}
//                 alt='Naver blog profile'
//               ></img>
//               <div>
//                 <h3>{data.account}</h3>
//                 <p className='title'>네이버 블로그</p>
//               </div>
//             </div>
//             <div className='body'>
//               <div className='banner'>
//                 <div ref={this.result}>
//                   <img
//                     src={
//                       'https://s3.ap-northeast-2.amazonaws.com/supermembers.app/badge.png'
//                     }
//                     alt='슈퍼멤버스 영향력 결과'
//                   />
//                   <div className='banner-body'>
//                     <span className='display-name'>{data.account}</span>
//                     <span className='percentage-title'>블로그 전체순위</span>
//                     <span className='percentage-number'>{`${data.rank.toLocaleString()}위`}</span>
//                   </div>
//                 </div>
//                 <div>
//                   <button onClick={this.handleSave}>
//                     <span className='mdi mdi-download'></span>
//                     <span>배너 저장</span>
//                   </button>
//                 </div>
//               </div>
//               <div className='fields'>
//                 <div className='field'>
//                   <h4>
//                     <span className='mdi mdi-seal-variant'></span>{' '}
//                     {`${data.rank}위`}
//                   </h4>
//                   <section>
//                     <span>네이버 블로그 전체 순위</span>
//                     <div className='icon'>
//                       <span className='mdi mdi-help-circle-outline'></span>
//                       <p>
//                         기존에 업로드한 컨텐츠의 노출 키워드 검색량과 상위노출
//                         여부를 점수화하여 측정된 지수입니다.
//                       </p>
//                     </div>
//                   </section>
//                 </div>
//                 <div className='field'>
//                   <h4>
//                     <span className='mdi mdi-equalizer'></span>{' '}
//                     {`${data.percentage.toFixed(2)}%`}
//                   </h4>
//                   <section>
//                     <span>상위 백분율</span>
//                     <div className='icon'>
//                       <span className='mdi mdi-help-circle-outline'></span>
//                       <p>
//                         300만 개의 유효한 블로그 중 순위를 퍼센트로 나타낸
//                         값입니다.
//                       </p>
//                     </div>
//                   </section>
//                 </div>
//                 <div className='field'>
//                   <h4>
//                     <span className='mdi mdi-cash-multiple'></span>{' '}
//                     {`₩${data.adFee.toLocaleString()}`}
//                   </h4>
//                   <section>
//                     <span>1회 예상 광고비용</span>
//                     <div className='icon'>
//                       <span className='mdi mdi-help-circle-outline'></span>
//                       <p>
//                         슈퍼멤버스가 자체적으로 계산한 예상 광고 비용입니다
//                         (상위노출 지수를 바탕으로 추산된 예상값이며, 실제와 다를
//                         수 있습니다)
//                       </p>
//                     </div>
//                   </section>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//         {data !== null && (
//           <div className='history'>
//             <div className='items'>
//               <div className='item'>
//                 <div className='underline'>일 방문자</div>
//                 <div className='content'>{data.visitor.toLocaleString()}</div>
//               </div>
//               <div className='item'>
//                 <div className='underline'>상위 백분율</div>
//                 <div className='content'>
//                   {`${data.percentage.toLocaleString()}%`}
//                 </div>
//               </div>
//             </div>
//             {histories}
//           </div>
//         )}
//         {data !== null && (
//           <div className='exposure'>
//             <section className='underline'>
//               <span className='title'>분야별 상위노출 비율</span>
//               <div className='icon'>
//                 <span className='mdi mdi-help-circle-outline'></span>
//                 <p>검색 결과 상위노출중인 컨텐츠들의 분야별 비율입니다.</p>
//               </div>
//             </section>
//             <div className='categories'>
//               <div className='chart-container'>
//                 <svg width={144} height={144} className='donut-chart'>
//                   {pieces}
//                 </svg>
//               </div>
//               <div className='legend-container'>{legend}</div>
//             </div>
//             <section className='underline'>
//               <span className='title'>상위노출 키워드</span>
//               <div className='icon'>
//                 <span className='mdi mdi-help-circle-outline'></span>
//                 <p>해당 키워드로 검색시 검색 결과에 노출되는 순위입니다.</p>
//               </div>
//             </section>
//             <div className='blur-container'>
//               {hideChart ? (
//                 <a
//                   target='_blank'
//                   href='https://rank.supermembers.co.kr/app?channel=naver'
//                   rel='noreferrer'
//                 >
//                   <img src={Chart3d} alt='상위노출 키워드' />
//                 </a>
//               ) : (
//                 <div className='keywords'>
//                   <table>
//                     <thead>
//                       <tr className='underline'>
//                         <th>
//                           <button>노출중인 키워드</button>
//                         </th>
//                         <th>
//                           <button onClick={() => this.onSortChange('mmqccnt')}>
//                             키워드검색량
//                           </button>
//                           <span
//                             className={
//                               currentSort[0] === 'mmqccnt'
//                                 ? 'mdi mdi-chevron-' + currentSort[1]
//                                 : ''
//                             }
//                           ></span>
//                         </th>
//                         <th>
//                           <button onClick={() => this.onSortChange('rank')}>
//                             노출순위
//                           </button>
//                           <span
//                             className={
//                               currentSort[0] === 'rank'
//                                 ? 'mdi mdi-chevron-' + currentSort[1]
//                                 : ''
//                             }
//                           ></span>
//                         </th>
//                       </tr>
//                     </thead>
//                     <tbody className='underline'>{keywords}</tbody>
//                   </table>
//                   {showAllKeyword ? (
//                     ''
//                   ) : (
//                     <button
//                       onClick={() => this.setState({ showAllKeyword: true })}
//                     >
//                       + 모두 보기
//                     </button>
//                   )}
//                 </div>
//               )}
//             </div>
//           </div>
//         )}
//         {data !== null && (
//           <div className='info'>
//             <p className='update'>
//               조회된 1회 예상 광고비용을 실제로 받을 수 있다면?
//             </p>
//             <button>
//               <a
//                 target='_blank'
//                 href='https://rank.supermembers.co.kr/app?channel=naver'
//                 rel='noreferrer'
//               >
//                 슈퍼멤버스 앱 다운로드
//                 <span className='mdi mdi-chevron-right'></span>
//               </a>
//             </button>
//             <p className='update'>매주 월요일 오후 2시 업데이트됩니다.</p>
//           </div>
//         )}
//       </div>
//     );
//   }
//   private handleSearch(evt: { keyCode: number }) {
//     if (evt.keyCode === 13) {
//       const { account } = this.state;
//       if (account.length < 3) {
//         this.setState({ alert: '네이버 아이디를 다시 확인해주세요.' });
//       } else if (account.includes('@')) {
//         this.setState({ alert: '이메일 형식이 아닌 아이디만 입력해주세요.' });
//       } else {
//         this.setState({ alert: '검색중입니다.', disabled: true });
//         axios
//           .get(
//             `https://api.supermembers.co.kr/influencer/rank?account=${account}`
//           )
//           .then((result) => {
//             if (result.data.value.length > 0) {
//               const categories = result.data.value[0].categories;
//               const sortable = Object.keys(result.data.value[0].categories).map(
//                 (key) => ({
//                   key,
//                   value: result.data.value[0].categories[key],
//                 })
//               );
//               sortable
//                 .filter((item) => item.key !== '기타')
//                 .sort((a, b) => b.value - a.value)
//                 .forEach((item) => (categories[item.key] = item.value));
//               categories['기타'] = result.data.value[0].categories['기타'] || 0;
//               result.data.value[0].categories = categories;
//               this.setState({
//                 alert: '상위 10만 개의 계정만 조회가 가능합니다.',
//                 disabled: false,
//                 data: result.data.value[0],
//               });
//             } else {
//               this.setState({
//                 alert: '상위 10만 개의 계정만 조회가 가능합니다.',
//                 disabled: false,
//                 data: null,
//               });
//             }
//           })
//           .catch((err) => {
//             this.setState({
//               alert: '입력하신 아이디에 관한 데이터 조회에 실패했습니다.',
//               disabled: false,
//               data: null,
//             });
//           });
//       }
//     }
//   }
//   private handleChange(evt: { target: { value: any } }) {
//     this.setState({ account: evt.target.value });
//   }
//   private handleSave() {
//     if (this.result && this.result.current) {
//       htmlToImage.toPng(this.result.current).then(function (dataUrl) {
//         var link = document.createElement('a');
//         link.download = 'supermembers.png';
//         link.href = dataUrl;
//         link.click();
//       });
//     }
//   }
//   private onSortChange(field: string) {
//     const { currentSort } = this.state;
//     let nextSort = [];

//     nextSort[0] = field;
//     if (currentSort[0] === field) {
//       if (currentSort[1] === 'down') nextSort[1] = 'up';
//       else if (currentSort[1] === 'up') nextSort[1] = 'down';
//       else if (currentSort[1] === 'default') nextSort[1] = 'down';
//     } else {
//       nextSort[1] = 'down';
//     }

//     this.setState({
//       currentSort: nextSort,
//     });
//   }
// }

// export default Rank;

import React, { useRef, useState } from 'react';
import axios from 'axios';
import * as htmlToImage from 'html-to-image';
import { Line } from 'react-chartjs-2';

import Chart1d from '../asset/chart1d.png';
import Chart3d from '../asset/chart3d.png';
import AppStore from '../asset/app-store.png';
import PlayStore from '../asset/play-store.png';
import './Rank.scss';
import { useParams } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';

const chartColorArr = [
  '#DD2333',
  '#E34956',
  '#EB717B',
  '#F29BA2',
  '#FAC8CC',
  '#ECEFF1',
];

const Rank = () => {
  const [account, setAccount] = useState('');
  const [alert, setAlert] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState<any>(null);
  const [currentSort, setCurrentSort] = useState(['mmqccnt', 'down']);
  const [showAllKeyword, setShowAllKeyword] = useState(false);

  const { show } = useParams();
  const hideChart = show !== 'show';
  let rotate = -90;
  const circumference = 2 * Math.PI * 62;

  const result = useRef<HTMLDivElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccount(e.target.value);
  };

  const handleSearch = (evt: { keyCode: number }) => {
    if (evt.keyCode === 13) {
      if (account.length < 3) {
        setAlert('네이버 아이디를 다시 확인해주세요.');
      } else if (account.includes('@')) {
        setAlert('이메일 형식이 아닌 아이디만 입력해주세요.');
      } else {
        setAlert('검색중입니다.');
        setDisabled(true);

        axios
          .get(
            `https://api.supermembers.co.kr/influencer/rank?account=${account}`
          )
          .then((result) => {
            if (result.data.value.length > 0) {
              const categories = result.data.value[0].categories;
              const sortable = Object.keys(result.data.value[0].categories).map(
                (key) => ({
                  key,
                  value: result.data.value[0].categories[key],
                })
              );
              sortable
                .filter((item) => item.key !== '기타')
                .sort((a, b) => b.value - a.value)
                .forEach((item) => (categories[item.key] = item.value));
              categories['기타'] = result.data.value[0].categories['기타'] || 0;
              result.data.value[0].categories = categories;

              setAlert('상위 30만 개의 계정만 조회가 가능합니다.');
              setDisabled(false);
              setData(result.data.value[0]);
            } else {
              setAlert('상위 30만 개의 계정만 조회가 가능합니다.');
              setDisabled(false);
              setData(null);
            }
          })
          .catch((err) => {
            setAlert('입력하신 아이디에 관한 데이터 조회에 실패했습니다.');
            setDisabled(false);
            setData(null);
          });
      }
    }
  };

  const handleSave = () => {
    if (result && result.current) {
      const elem = result.current;

      htmlToImage.toPng(elem).then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'supermembers.png';
        link.href = dataUrl;
        link.click();
      });
    }
  };

  const onSortChange = (key: string) => {
    setCurrentSort([key, currentSort[1] === 'down' ? 'up' : 'down']);
  };

  const histories =
    data !== null &&
    ['rank', 'adFee'].map((key, idx) => {
      return (
        <div className='chart-container'>
          <div className='underline'>
            {key === 'rank' ? '주간 블로그 순위' : '1회 예상 광고비용 추이'}
          </div>
          <div className='chart'>
            <div className='item'>
              <div className='rank'>
                {`${
                  data[key].toLocaleString() + (key === 'rank' ? '위' : '원')
                }`}
              </div>
              <div className='caption'>매주 월요일 기준</div>
            </div>
            <div className='blur-container'>
              <div className='line-chart'>
                {hideChart ? (
                  <a
                    target='_blank'
                    href='https://rank.supermembers.co.kr/app?channel=naver'
                    rel='noreferrer'
                  >
                    <img src={Chart1d} alt='차트' />
                  </a>
                ) : (
                  <Line
                    data={{
                      labels: data.histories.map(
                        (history: any) =>
                          history.week.split('-')[1] +
                          '.' +
                          history.week.split('-')[2]
                      ),
                      datasets: [
                        {
                          data: data.histories.map((history: any) =>
                            Math.round(history[key])
                          ),
                          fill: false,
                          backgroundColor: 'rgb(189, 19, 40)',
                          borderColor: 'rgb(189, 19, 40, 0.8)',
                          tension: 0.5,
                          borderWidth: 2,
                        },
                      ],
                    }}
                    options={{
                      hover: {
                        mode: 'index',
                        intersect: false,
                      },
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                          time: {
                            displayFormats: {
                              day: 'MM.DD',
                            },
                          },
                          ticks: {
                            source: 'data',
                          },
                        },
                        y: {
                          reverse: key === 'rank',
                          beginAtZero: true,
                          ticks: {
                            precision: 0,
                            callback: function (value) {
                              return value
                                .toLocaleString()
                                .padStart(6, String.fromCharCode(8192));
                            },
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });

  const legend =
    data !== null &&
    Object.keys(data.categories).map((key, idx) => {
      return (
        <div
          className='capsule'
          style={{ justifyContent: 'space-between' }}
          key={`legend${idx}`}
        >
          <div className='capsule'>
            <div
              className='chip'
              style={{ backgroundColor: chartColorArr[idx] }}
            ></div>
            <p className='legend'>{key}</p>
          </div>
          <p className='legend'>{`${data.categories[key]}%`}</p>
        </div>
      );
    });
  const pieces =
    data !== null &&
    Object.keys(data.categories).map((key, idx) => {
      const item = (
        <circle
          key={`piece${idx}`}
          r={62}
          cx={72}
          cy={72}
          transform={`rotate(${rotate} 72 72)`}
          style={{
            strokeDasharray: `${
              (data.categories[key] * circumference) / 100
            } ${circumference}`,
            stroke: chartColorArr[idx],
          }}
          className='donut-piece'
        />
      );
      rotate += (data.categories[key] * 2 * 180) / 100;
      return item;
    });

  const sortTypes: any = {
    up: {
      class: 'sort-up',
      fn: (a: any, b: any) =>
        currentSort[0] === 'rank'
          ? a.ids.indexOf(account) - b.ids.indexOf(account)
          : a[currentSort[0]] - b[currentSort[0]],
    },
    down: {
      class: 'sort-down',
      fn: (a: any, b: any) =>
        currentSort[0] === 'rank'
          ? b.ids.indexOf(account) - a.ids.indexOf(account)
          : b[currentSort[0]] - a[currentSort[0]],
    },
    default: {
      class: 'sort',
      fn: (a: any, b: any) => a,
    },
  };

  const keywords =
    data !== null &&
    data.keywords
      .sort(sortTypes[currentSort[1]].fn)
      .slice(0, showAllKeyword ? undefined : 5)
      .map((item: any) => (
        <tr>
          <td>{item.name}</td>
          <td>{(item.mmqccnt || 0).toLocaleString()}</td>
          <td>{(item.ids || []).indexOf(account) + 1 + '위'}</td>
        </tr>
      ));

  return (
    <>
      <div className='rank content-wrapper flex column'>
        <div className={`header ${data !== null && 'searched'}`}>
          <h2>슈퍼멤버스</h2>
          <h1>인플루언서 랭킹</h1>
        </div>
        <div className={`search-input ${data !== null && 'searched'}`}>
          <SearchIcon />
          <input
            placeholder='네이버 아이디를 검색해보세요.'
            disabled={disabled}
            value={account}
            onKeyUp={handleSearch}
            onChange={handleChange}
          />
        </div>
        <p className={`alert ${data !== null && 'searched'}`}>{alert}</p>
        {data === null && (
          <div className='info'>
            <p>
              네이버 블로그를 운영하고 계신가요?
              <br />
              <br />
              네이버 아이디를 입력하시면,
              <br /> 슈퍼멤버스 영향력 측정 엔진을 기반으로 분석된
              <br />
              블로그 랭킹을 확인할 수 있습니다.
            </p>
            <p className='update'>
              조회된 1회 예상 광고비용을 실제로 받을 수 있다면?
            </p>
            <div>
              <p
                style={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  color: '#3A3A3A',
                  textAlign: 'center',
                  fontSize: '13px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
              >
                앱 다운로드
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '12px',
                }}
              >
                <a
                  href='https://apps.apple.com/kr/app/슈퍼멤버스-네이버블로그용/id1242322360'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={AppStore}
                    alt='앱스토어'
                    style={{ width: '140px' }}
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=kr.co.mayacrew.supermembers'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={PlayStore}
                    alt='플레이스토어'
                    style={{ width: '140px' }}
                  />
                </a>
              </div>
            </div>
          </div>
        )}
        {data !== null && (
          <div className='search-result'>
            <div className='header'>
              <img
                className='photo'
                src={data.photo}
                width={40}
                height={40}
                alt='Naver blog profile'
              ></img>
              <div>
                <h3>{data.account}</h3>
                <p className='title'>네이버 블로그</p>
              </div>
            </div>
            <div className='body'>
              <div className='banner'>
                <div ref={result}>
                  <img
                    src={
                      'https://s3.ap-northeast-2.amazonaws.com/supermembers.app/badge.png'
                    }
                    alt='슈퍼멤버스 영향력 결과'
                  />
                  <div className='banner-body'>
                    <span className='display-name'>{data.account}</span>
                    <span className='percentage-title'>블로그 전체순위</span>
                    <span className='percentage-number'>{`${data.rank.toLocaleString()}위`}</span>
                  </div>
                </div>
                <div>
                  <button onClick={handleSave}>
                    <span className='mdi mdi-download'></span>
                    <span>배너 저장</span>
                  </button>
                </div>
              </div>
              <div className='fields'>
                <div className='field'>
                  <h4>
                    <span className='mdi mdi-seal-variant'></span>{' '}
                    {`${data.rank}위`}
                  </h4>
                  <section>
                    <span>네이버 블로그 전체 순위</span>
                    <div className='icon'>
                      <span className='mdi mdi-help-circle-outline'></span>
                      <p>
                        기존에 업로드한 컨텐츠의 노출 키워드 검색량과 상위노출
                        여부를 점수화하여 측정된 지수입니다.
                      </p>
                    </div>
                  </section>
                </div>
                <div className='field'>
                  <h4>
                    <span className='mdi mdi-equalizer'></span>{' '}
                    {!!data.percentage ? `${data.percentage.toFixed(2)}%` : ''}
                  </h4>
                  <section>
                    <span>상위 백분율</span>
                    <div className='icon'>
                      <span className='mdi mdi-help-circle-outline'></span>
                      <p>
                        300만 개의 유효한 블로그 중 순위를 퍼센트로 나타낸
                        값입니다.
                      </p>
                    </div>
                  </section>
                </div>
                <div className='field'>
                  <h4>
                    <span className='mdi mdi-cash-multiple'></span>{' '}
                    {`₩${data.adFee.toLocaleString()}`}
                  </h4>
                  <section>
                    <span>1회 예상 광고비용</span>
                    <div className='icon'>
                      <span className='mdi mdi-help-circle-outline'></span>
                      <p>
                        슈퍼멤버스가 자체적으로 계산한 예상 광고 비용입니다
                        (상위노출 지수를 바탕으로 추산된 예상값이며, 실제와 다를
                        수 있습니다)
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
        {data !== null && (
          <div className='history'>
            <div className='items'>
              <div className='item'>
                <div className='underline'>일 방문자</div>
                <div className='content'>{data.visitor.toLocaleString()}</div>
              </div>
              <div className='item'>
                <div className='underline'>상위 백분율</div>
                <div className='content'>
                  {!!data.percentage
                    ? `${data.percentage.toLocaleString()}%`
                    : ''}
                </div>
              </div>
            </div>
            {histories}
          </div>
        )}
        {data !== null && (
          <div className='exposure'>
            <section className='underline'>
              <span className='title'>분야별 상위노출 비율</span>
              <div className='icon'>
                <span className='mdi mdi-help-circle-outline'></span>
                <p>검색 결과 상위노출중인 컨텐츠들의 분야별 비율입니다.</p>
              </div>
            </section>
            <div className='categories'>
              <div className='chart-container'>
                <svg width={144} height={144} className='donut-chart'>
                  {pieces}
                </svg>
              </div>
              <div className='legend-container'>{legend}</div>
            </div>
            <section className='underline'>
              <span className='title'>상위노출 키워드</span>
              <div className='icon'>
                <span className='mdi mdi-help-circle-outline'></span>
                <p>해당 키워드로 검색시 검색 결과에 노출되는 순위입니다.</p>
              </div>
            </section>
            <div className='blur-container'>
              {hideChart ? (
                <a
                  target='_blank'
                  href='https://rank.supermembers.co.kr/app?channel=naver'
                  rel='noreferrer'
                >
                  <img src={Chart3d} alt='상위노출 키워드' />
                </a>
              ) : (
                <div className='keywords'>
                  <table>
                    <thead>
                      <tr className='underline'>
                        <th>
                          <button>노출중인 키워드</button>
                        </th>
                        <th>
                          <button onClick={() => onSortChange('mmqccnt')}>
                            키워드검색량
                          </button>
                          <span
                            className={
                              currentSort[0] === 'mmqccnt'
                                ? 'mdi mdi-chevron-' + currentSort[1]
                                : ''
                            }
                          ></span>
                        </th>
                        <th>
                          <button onClick={() => onSortChange('rank')}>
                            노출순위
                          </button>
                          <span
                            className={
                              currentSort[0] === 'rank'
                                ? 'mdi mdi-chevron-' + currentSort[1]
                                : ''
                            }
                          ></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='underline'>{keywords}</tbody>
                  </table>
                  {showAllKeyword ? (
                    ''
                  ) : (
                    <button
                      onClick={() => {
                        setShowAllKeyword(true);
                      }}
                    >
                      + 모두 보기
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {data !== null && (
          <div className='info'>
            <p className='update'>
              조회된 1회 예상 광고비용을 실제로 받을 수 있다면?
            </p>
            <button>
              <a
                target='_blank'
                href='https://rank.supermembers.co.kr/app?channel=naver'
                rel='noreferrer'
              >
                슈퍼멤버스 앱 다운로드
                <span className='mdi mdi-chevron-right'></span>
              </a>
            </button>
            <p className='update'>매주 월요일 오후 2시 업데이트됩니다.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Rank;
