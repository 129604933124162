import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './page/Main';
import Rank from './page/Rank';
import Service from './page/Service';
import Terms from './page/Terms';
import Privacy from './page/Privacy';

import Header from './component/Header';
import Footer from './component/Footer';

import GlobalStyle from './GlobalStyles';

import Onboarding from './page/receipt/Onboarding';
import ContactForm from './page/receipt/ContactForm';
import ReviewSubmission from './page/receipt/ReviewSubmission';
import ReviewGuide from './page/receipt/ReviewGuide';
import ReviewConfirmation from './page/receipt/ReviewConfirmation';
import InvalidStore from './page/receipt/InvalidStore';
import Loading from './components/ui/Loading';
import Map from './page/Map';

import StoreVerificationRoute from './components/StoreVerificationRoute';

import { useRecoilValue } from 'recoil';
import { isLoadingState } from './store/atoms';
import TargetMap from './page/TargetMap';

const AppInenr = () => {
  const isLoading = useRecoilValue(isLoadingState);

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        {isLoading && <Loading />}
        <Header />
        <Routes>
          <Route path='/' Component={Main} />
          <Route path='/rank' Component={Rank} />
          <Route path='/rank/:show' Component={Rank} />
          <Route path='/service' Component={Service} />
          <Route path='/terms' Component={Terms} />
          <Route path='/privacy' Component={Privacy} />
          <Route element={<StoreVerificationRoute />}>
            <Route path='/receipt/:storeId' element={<Onboarding />} />
          </Route>
          <Route element={<StoreVerificationRoute />}>
            <Route path='/receipt/contact/:storeId' element={<ContactForm />} />
          </Route>
          <Route element={<StoreVerificationRoute />}>
            <Route path='/receipt/guide/:storeId' element={<ReviewGuide />} />
          </Route>
          <Route element={<StoreVerificationRoute />}>
            <Route
              path='/receipt/post/:storeId'
              element={<ReviewSubmission />}
            />
          </Route>
          <Route element={<StoreVerificationRoute />}>
            <Route
              path='/receipt/result/:storeId'
              element={<ReviewConfirmation />}
            />
          </Route>
          <Route path='/receipt/invalid-store' Component={InvalidStore} />
          <Route path='/map/company' Component={Map} />
          <Route path='/map/company/target' Component={TargetMap} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default AppInenr;
