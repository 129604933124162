const enum Color {
  primary1 = 'rgba(234, 42, 58, 1)',
  primary2 = '#FDEAEB',

  default1 = 'rgba(128, 128, 128, 1)',
  default2 = 'rgba(58, 58, 58, 1)',
  default3 = 'rgba(32, 32, 32, 1)',

  disabled1 = 'rgba(245, 245, 245, 1)',
  disabled2 = 'rgba(219, 219, 219, 1)',
  disabled3 = 'rgba(194, 194, 194, 1)',

  primary_opacity1 = 'rgba(234, 42, 58, 0.1)',
  default_3_opacity = 'rgba(32, 32, 32, 0.4)',

  inversed = 'rgba(255, 255, 255, 1)',
}

export default Color;
