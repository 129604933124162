/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Step4Icon from '../../asset/landing/Step4.png';
import useWindowWidth from '../../hooks/useWindowWidth';
import WebTypos from '../typography/Web';

const Step4 = () => {
  const windowWidth = useWindowWidth();
  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 150px;

        @media (max-width: 1320px) {
          gap: 0px;
          flex-direction: column-reverse;
        }
      `}
    >
      <div css={css``}>
        <video
          autoPlay
          muted
          loop
          css={css`
            width: 620px;

            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        >
          <source
            src={`${process.env.PUBLIC_URL}/videos/ranking_desktop_x3.mp4`}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 400px;
          margin-right: 110px;

          @media (max-width: 1320px) {
            gap: 12px;
            text-align: center;
            margin-right: 0px;
            width: max-content;
          }
        `}
      >
        {windowWidth > 1320 && (
          <img
            src={Step4Icon}
            alt='Step4Icon'
            css={css`
              height: 72px;
              width: fit-content;
            `}
          />
        )}

        <p
          css={css`
            ${WebTypos.Headline2};
            @media (max-width: 1320px) {
              ${WebTypos.Headline4};
            }
          `}
        >
          블로그 랭킹 조회
        </p>
        <p
          css={css`
            ${WebTypos.Body3};
            @media (max-width: 1320px) {
              ${WebTypos.Body6};
              margin-bottom: 40px;
            }
          `}
        >
          본인의 블로그와 키워드 순위를 <br />
          실시간으로 확인하며 성장을 지켜보세요.
        </p>
      </div>
    </div>
  );
};

export default Step4;
