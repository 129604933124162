/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typos from '../../../typography';
import Color from '../../../color';

interface Props {
  title: string;
  description?: string;
}

const Header = ({ title, description }: Props) => {
  return (
    <header>
      <h1
        css={css`
          color: ${Color.default2};
          ${Typos.Headline};
          white-space: pre-line;
        `}
      >
        {title}
      </h1>
      <h3
        css={css`
          color: ${Color.default1};
          ${Typos.Subline2};
          margin-top: 8px;
          white-space: pre-line;
        `}
      >
        {description}
      </h3>
    </header>
  );
};

export default Header;
