/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import VerticalSpace from './VerticalSpace';

interface Props {
  children: React.ReactNode;
  footerElement?: React.ReactNode;
  footerPadding?: string;
}

const Container = ({ children, footerElement, footerPadding }: Props) => {
  return (
    <>
      <section
        css={css`
          width: 100vw;
          height: calc(100vh - 72px);
          display: flex;
          flex-direction: column;
        `}
      >
        {children}
      </section>
      <footer
        css={css`
          position: fixed;
          bottom: 0;
          width: 100%;
          padding: ${footerPadding};
        `}
      >
        {footerElement}
        <VerticalSpace size={20} />
      </footer>
    </>
  );
};

export default Container;
