/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Color from '../color';
import Typos from '../typography';

export interface ToastMessageProps {
  showToast: (message: string) => void;
}

const ToastMessage = forwardRef<ToastMessageProps, any>((props, ref) => {
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const toastStyles = css`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${Color.default_3_opacity};
    color: ${Color.inversed};
    padding: 8px 12px;
    border-radius: 8px;
    opacity: ${isVisible ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
    pointer-events: none;
    bottom: 88px;
    ${Typos.Body4};
  `;

  const showToast = (text: string) => {
    setMessage(text);
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  };

  useImperativeHandle(ref, () => ({
    showToast,
  }));

  return (
    <div css={toastStyles}>
      <div>{message}</div>
    </div>
  );
});

export default ToastMessage;
