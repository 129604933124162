/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface Props {
  size: number;
}

const VerticalSpace = ({ size }: Props) => {
  return (
    <div
      css={css`
        padding-top: ${size}px;
      `}
    ></div>
  );
};

export default VerticalSpace;
