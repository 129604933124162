/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '../color';
import WebTypos from '../typography/Web';
import GuideImg1 from '../../asset/landing/guide1.png';
import GuideImg2 from '../../asset/landing/guide2.png';
import GuideImg3 from '../../asset/landing/guide3.png';

const Guide = () => {
  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: 1320px) {
        }
      `}
    >
      <div
        css={css`
          width: 60px;
          height: 2px;
          background-color: ${Color.default3};
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 60px;

          @media (max-width: 1320px) {
            width: 28px;
            margin-bottom: 20px;
          }
        `}
      ></div>
      <p
        css={css`
          text-align: center;
          ${WebTypos.Headline2};
          margin-bottom: 80px;

          @media (max-width: 1320px) {
            ${WebTypos.Headline5};
            margin-bottom: 20px;
          }
        `}
      >
        슈퍼멤버스 안에서 <span className='mobile_br' /> 혜택이 가득한 생활을
        누려보세요!
      </p>
      <div
        css={css`
          display: flex;
          gap: 40px;
          align-items: center;
          justify-content: center;

          @media (max-width: 1320px) {
            gap: 20px;
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: ${Color.disabled1};
            border-radius: 8px;
            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1320px) {
              padding-left: 20px;
              padding-right: 20px;
              justify-content: flex-start;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              width: 120px;
              height: 120px;
              align-items: center;
              justify-content: center;

              @media (max-width: 1320px) {
                width: 100px;
                height: 100px;
              }
            `}
          >
            <img
              css={css`
                width: 72px;

                @media (max-width: 1320px) {
                  width: 60px;
                }
              `}
              src={GuideImg1}
              alt='GuideImg1'
            />
          </div>
          <div
            css={css`
              width: 164px;
            `}
          >
            <p
              css={css`
                ${WebTypos.Headline5};
              `}
            >
              서비스 이용 안내
            </p>
            <p
              css={css`
                ${WebTypos.Body7};
              `}
            >
              앱에서 서비스 이용방법을
              <br /> 미리 알아보세요.
            </p>
          </div>
        </div>
        <div
          css={css`
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: ${Color.disabled1};
            border-radius: 8px;
            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1320px) {
              padding-left: 20px;
              padding-right: 20px;
              justify-content: flex-start;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              width: 120px;
              height: 120px;
              align-items: center;
              justify-content: center;

              @media (max-width: 1320px) {
                width: 100px;
                height: 100px;
              }
            `}
          >
            <img
              css={css`
                width: 72px;

                @media (max-width: 1320px) {
                  width: 60px;
                }
              `}
              src={GuideImg2}
              alt='GuideImg2'
            />
          </div>
          <div
            css={css`
              width: 164px;
            `}
          >
            <p
              css={css`
                ${WebTypos.Headline5};
              `}
            >
              리뷰 작성 안내
            </p>
            <p
              css={css`
                ${WebTypos.Body7};
              `}
            >
              블로그 포스팅 시, 작성 방법을
              <br /> 미리 알아보세요.
            </p>
          </div>
        </div>
        <div
          css={css`
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: ${Color.disabled1};
            border-radius: 8px;
            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1320px) {
              padding-left: 20px;
              padding-right: 20px;
              justify-content: flex-start;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              width: 120px;
              height: 120px;
              align-items: center;
              justify-content: center;

              @media (max-width: 1320px) {
                width: 100px;
                height: 100px;
              }
            `}
          >
            <img
              css={css`
                width: 72px;

                @media (max-width: 1320px) {
                  width: 60px;
                }
              `}
              src={GuideImg3}
              alt='GuideImg3'
            />
          </div>
          <div
            css={css`
              width: 164px;
            `}
          >
            <p
              css={css`
                ${WebTypos.Headline5};
              `}
            >
              카카오톡 문의
            </p>
            <p
              css={css`
                ${WebTypos.Body7};
              `}
            >
              문의 사항 및 건의 사항을
              <br /> 카카오톡으로 문의해보세요.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Guide;
