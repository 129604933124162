/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Top from '../components/Landing/Top';

import Step1 from '../components/Landing/Step1';
import Step2 from '../components/Landing/Step2';
import Step3 from '../components/Landing/Step3';
import Step4 from '../components/Landing/Step4';
import Step5 from '../components/Landing/Step5';
import Step6 from '../components/Landing/Step6';
import Guide from '../components/Landing/Guide';
import Ending from '../components/Landing/Ending';

import Background from '../asset/landing/landing_bg.png';
import AppStore from '../asset/landing/appstore.png';
import PlayStore from '../asset/landing/playstore.png';

const Main = () => {
  return (
    <article
      css={css`
        background-image: url(${Background});
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
      `}
    >
      <div
        css={css`
          padding-bottom: 130px;
        `}
      />
      <Top />
      <div
        css={css`
          padding-bottom: 130px;
        `}
      />
      <Step1 />
      <div
        css={css`
          padding-bottom: 180px;
        `}
      />
      <Step2 />
      <div
        css={css`
          padding-bottom: 180px;
        `}
      />
      <Step3 />
      <div
        css={css`
          padding-bottom: 180px;
        `}
      />
      <Step4 />
      <div
        css={css`
          padding-bottom: 180px;
        `}
      />
      <Step5 />
      <div
        css={css`
          padding-bottom: 180px;
        `}
      />
      <Step6 />
      <div
        css={css`
          padding-bottom: 130px;
        `}
      />
      <div
        css={css`
          padding-bottom: 130px;
        `}
      />
      <Guide />
      <div
        css={css`
          padding-bottom: 130px;
        `}
      />
      <Ending />

      <div
        css={css`
          position: fixed;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 28px;

          @media (max-width: 1320px) {
            gap: 12px;
          }
        `}
      >
        <a href='https://apps.apple.com/kr/app/슈퍼멤버스-네이버블로그용/id1242322360'>
          <img
            src={AppStore}
            alt='AppStore'
            css={css`
              width: 260px;

              @media (max-width: 1320px) {
                width: 140px;
              }
            `}
          />
        </a>
        <a href='https://play.google.com/store/apps/details?id=kr.co.mayacrew.supermembers'>
          <img
            src={PlayStore}
            alt='PlayStore'
            css={css`
              width: 260px;

              @media (max-width: 1320px) {
                width: 140px;
              }
            `}
          />
        </a>
      </div>
    </article>
  );
};

export default Main;
