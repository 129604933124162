/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '../../color';
import Shadow from '../../shadow/index';

interface Props {
  URL: string;
  isActive?: boolean;
}

const CardImage = ({ URL, isActive }: Props) => {
  return (
    <div
      css={css`
        width: 152px;
        height: 152px;
        ${Shadow};
        border-radius: 8px;
        ${isActive &&
        `
        box-shadow: 0 0 0 2px ${Color.primary1} inset;
        background-color: ${Color.primary_opacity1};
        `};
      `}
    >
      <img
        src={URL}
        alt='혜택 이미지'
        css={css`
          width: 100%;
          height: 100%;
        `}
      />
    </div>
  );
};

export default CardImage;
