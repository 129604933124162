import { useEffect, useRef } from 'react';
import Inactive from '../asset/pins/inactive.png';
import User from '../asset/pins/userLocation.png';

const TargetMap = () => {
  const mapRef = useRef(null);

  const callMessage = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'INIT',
        })
      );
    } else {
      console.log('ReactNativeWebView is not available');
    }
  };

  const convertQueryToCoordinates = (object) => {
    return {
      lat: Number(object.lat),
      lng: Number(object.lng),
    };
  };

  const settingMarker = (data) => {
    try {
      const { naver } = window;
      const company = data.company;
      const user = data.user;
      const companyCoordinate = convertQueryToCoordinates(company);

      const location = new naver.maps.LatLng(
        companyCoordinate.lat,
        companyCoordinate.lng
      );

      new naver.maps.Marker({
        position: location,
        map: mapRef.current,
        icon: {
          content: `<img src="${Inactive}" style="width: 24px; height: 24px;"/>`,
          size: new naver.maps.Size(24, 24),
        },
      });

      mapRef.current.setCenter(location);

      if (!!user) {
        const userCoordinate = convertQueryToCoordinates(user);

        new naver.maps.Marker({
          position: new naver.maps.LatLng(
            userCoordinate.lat,
            userCoordinate.lng
          ),
          map: mapRef.current,
          icon: {
            content: `<img src="${User}" style="width: 36px; height: 36px;"/>`,
            size: new naver.maps.Size(36, 36),
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      switch (event.data.action) {
        case 'SET_LOCATION':
          settingMarker(event.data.value);
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    // 지도 초기화 및 설정
    const initMap = () => {
      const { naver } = window;
      const mapOptions = {
        center: new naver.maps.LatLng(37.534515, 126.989693),
        zoomControl: false,
        zoom: 14,
        draggable: false, // 드래그 이동 비활성화
        pinchZoom: false, // 터치 줌 비활성화
        scrollWheel: false, // 스크롤 휠 줌 비활성화
        disableDoubleTapZoom: true, // 더블 탭 줌 비활성화
        disableTwoFingerTapZoom: true, // 두 손가락 탭 줌 비활성화
      };
      mapRef.current = new naver.maps.Map('map', mapOptions);
      callMessage();
    };
    initMap();
  }, []);

  return <div id='map' style={{ width: '100vw', height: '100vh' }}></div>;
};

export default TargetMap;
