/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Step1Icon from '../../asset/landing/Step1.png';
import Step1Img from '../../asset/landing/step1Img.png';
import useWindowWidth from '../../hooks/useWindowWidth';
import WebTypos from '../typography/Web';

const Step1 = () => {
  const windowWidth = useWindowWidth();

  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 150px;

        @media (max-width: 1320px) {
          flex-direction: column;
          gap: 0px;
        }
      `}
    >
      <div
        css={css`
          width: 400px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-left: 110px;

          @media (max-width: 1320px) {
            gap: 12px;
            margin-left: 0px;
            text-align: center;
            width: max-content;
          }
        `}
      >
        {windowWidth > 1320 && (
          <img
            src={Step1Icon}
            alt='Step1Icon'
            css={css`
              height: 72px;
              width: fit-content;
            `}
          />
        )}
        <p
          css={css`
            ${WebTypos.Headline2};

            @media (max-width: 1320px) {
              ${WebTypos.Headline4};
            }
          `}
        >
          당일 방문, <br /> 당연히 가능해요
        </p>
        <p
          css={css`
            ${WebTypos.Body3};
            @media (max-width: 1320px) {
              ${WebTypos.Body6};
              margin-bottom: 40px;
            }
          `}
        >
          체험단 신청에 지치셨나요? <br />
          이제 슈퍼멤버스로 당일 방문하세요.
        </p>
      </div>
      <div css={css``}>
        <img
          src={Step1Img}
          alt='Step1Img'
          css={css`
            width: 620px;

            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        />
      </div>
    </div>
  );
};

export default Step1;
