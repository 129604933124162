/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../components/layout/Container';
import Header from '../../components/ui/Text/Header/Header';
import Button from '../../components/ui/buttons/Button';
import VerticalSpace from '../../components/layout/VerticalSpace';
import HorizontalSpace from '../../components/layout/HorizontalSpace';

import { useNavigate, useParams } from 'react-router-dom';

import React, { useMemo, useRef, useState } from 'react';
import EmojiField from '../../components/ui/fields/EmojiField';
import GhostButton from '../../components/ui/buttons/GhostButton';
import TutorialText from '../../components/ui/Text/TutorialText/TutorialText';

import Carousel from '../../components/ui/Carousel';

import { useSetRecoilState } from 'recoil';
import { reviewInputState } from '../../store/atoms';
import Tag from '../../components/ui/Tag';
import Step1 from '../../asset/step-1.png';
import Step2 from '../../asset/step-2.png';
import Step3 from '../../asset/step-3.png';
import ToastMessage, {
  ToastMessageProps,
} from '../../components/ui/ToastMessage';

const EMOJIS = [
  '😊',
  '😍',
  '🥰',
  '😘',
  '😂',
  '😆',
  '😎',
  '🤩',
  '🤗',
  '🙏',
  '🤣',
  '😇',
  '🥳',
  '❤️',
  '💕',
  '💖',
  '😋',
  '😁',
  '👍',
  '🫶',
  '💓',
  '⭐️',
  '✨',
  '🔥',
];

const ReviewGuide = () => {
  const navigate = useNavigate();
  const { storeId } = useParams();
  const setReviseInput = useSetRecoilState(reviewInputState);

  const [curentSlidePage, setCurentSlidePage] = useState(0);

  const toastRef = useRef<ToastMessageProps>(null);

  const GUIDE_MAP = {
    0: {
      title: `이모티콘을 리뷰에 붙여주세요`,
      description: `리뷰를 작성 하실 때, 인증 이모티콘을 넣어주세요.
        이모티콘을 문장 중간에 따로 넣어도 되어요.`,
    },
    1: {
      title: `공유하기 버튼을 눌러주세요`,
      description: `우측 상단의 버튼을 누르고,
        공유하기 버튼을 눌러주세요.`,
    },
    2: {
      title: `URL 복사를 해주세요.`,
      description: `URL 복사를 하시고, 다음 화면에서
        리뷰 URL 을 붙여넣어 주세요.`,
    },
  };

  const IMG_MAP = {
    1: Step1,
    2: Step2,
    3: Step3,
  };

  const randomEmojiPicker = () => {
    const temp: string[] = [];
    while (temp.length <= 1) {
      const randomNumber = Math.floor(Math.random() * EMOJIS.length);
      const emoji = EMOJIS[randomNumber];

      if (!temp.includes(emoji)) {
        temp.push(emoji);
      }
    }

    return temp.join('');
  };

  const randomPicks = useMemo(() => randomEmojiPicker(), []);

  const movePost = () => {
    window.open('https://m.place.naver.com/my/checkin', '_blank');
    setReviseInput((prev) => ({ ...prev, emoji: randomPicks }));
    navigate(`/receipt/post/${storeId}`);
  };

  const copyEmoji = () => {
    toastRef.current?.showToast('이모티콘 복사 완료');
    navigator.clipboard.writeText(randomPicks);
  };

  return (
    <Container
      footerElement={
        <Button label='영수증 리뷰 인증하기' onClick={movePost} isRounded />
      }
      footerPadding='0px 24px'
    >
      <div
        css={css`
          flex: 1;
          padding: 0px 24px;
        `}
      >
        <VerticalSpace size={44} />
        <Header
          title={`아래 인증 이모티콘을 포함해서
          포토리뷰를 남겨주세요.`}
        />
        <VerticalSpace size={16} />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <EmojiField emoji={randomPicks} />
          <HorizontalSpace size={12} />
          <GhostButton label='복사' onClick={copyEmoji} />
        </div>
        <VerticalSpace size={16} />
        <Carousel
          isShowDots
          slideChangeCallback={(index) => {
            setCurentSlidePage(index);
          }}
        >
          {[1, 2, 3].map((v) => (
            <Carousel.CarouselItem key={v}>
              <Tag
                label={`step${v}`}
                position='absolute'
                left='20px'
                top='16px'
              />
              <img
                alt={`step${v}`}
                src={IMG_MAP[v as 1 | 2 | 3]}
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
              />
            </Carousel.CarouselItem>
          ))}
        </Carousel>
        <VerticalSpace size={24} />
        <TutorialText
          title={GUIDE_MAP[curentSlidePage as 0 | 1 | 2].title}
          description={GUIDE_MAP[curentSlidePage as 0 | 1 | 2].description}
        />
      </div>
      <ToastMessage ref={toastRef} />
    </Container>
  );
};

export default ReviewGuide;
