/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typos from '../../typography';
import Color from '../../color';

type ButtonProps = {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  margin?: string;
};

const GhostButton = ({ label, onClick, margin }: ButtonProps) => {
  const buttonStyle = css`
    background-color: ${Color.inversed};
    border: none;
    cursor: pointer;
    margin: ${margin};
    padding-left: 24px;
    padding-right: 24px;
    ${Typos.Body4}
    color: ${Color.primary1};
    border: 2px solid ${Color.primary1};
    border-radius: 8px;
    height: 52px;
  `;

  return (
    <button css={buttonStyle} onClick={onClick}>
      {label}
    </button>
  );
};

export default GhostButton;
