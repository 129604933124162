/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '../color';
import Typos from '../typography';

interface Props {
  label: string;
  position?: 'relative' | 'absolute';
  left?: string;
  top?: string;
}

const Tag = ({ label, position, left, top }: Props) => {
  return (
    <span
      css={css`
        padding: 2px 12px;
        width: max-content;
        background-color: ${Color.primary1};
        color: ${Color.inversed};
        ${Typos.Body4};
        position: ${position};
        left: ${left};
        top: ${top};
        border-radius: 8px;
      `}
    >
      {label}
    </span>
  );
};

export default Tag;
