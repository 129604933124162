import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import Inactive from '../asset/pins/inactive.png';
import Active from '../asset/pins/active.png';
import FInactive from '../asset/pins/inactive_f.png';
import FActive from '../asset/pins/active_f.png';
import { MarkerClustering } from '../utils/cluster';

const Map = () => {
  const [company, setCompany] = useState([]);
  const [likeList, setLikeList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const classRef = useRef();

  const initSelectedCompany = () => {
    setSelectedCompany(null);
  };

  const callMessage = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'INIT',
        })
      );
    } else {
      console.log('ReactNativeWebView is not available');
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      switch (event.data.action) {
        case 'SET_COMPANY':
          setCompany(event.data.value);
          break;
        case 'SET_LOCATION':
          if (mapRef.current) {
            const { naver } = window;
            const location = new naver.maps.LatLng(
              event.data.value.lat,
              event.data.value.lng
            );
            mapRef.current.setZoom(17);
            mapRef.current.panTo(location);
          }
          break;
        case 'SET_LIKED':
          setLikeList(event.data.value);
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'COMPANY',
          value: selectedCompany,
        })
      );
    } else {
      console.log('ReactNativeWebView is not available');
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!mapRef.current || company.length === 0) return;

    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    const markers = company.map((c) => createMarker(c));
    markersRef.current = markers;

    const { naver } = window;
    naver.maps.Event.addListener(mapRef.current, 'click', initSelectedCompany);

    function createMarker(c) {
      const { naver } = window;

      const isLiked = likeList.includes(String(c.id));
      const icon = isLiked ? FInactive : Inactive;

      const m = new naver.maps.Marker({
        map: mapRef.current,
        position: new naver.maps.LatLng(c.lat, c.lon),
        icon: icon,
      });
      naver.maps.Event.addListener(m, 'click', function (e) {
        mapRef.current.panTo(e.coord);
        setSelectedCompany(c);
      });

      return m;
    }

    function updateMarkers(markers) {
      const htmlMarker1 = {
          content: `
                  <div style='width: 24px; height: 24px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.70);
                  display: flex; align-items: center; justify-content: center'>
                    <span style='color: white; font-size: 14px; font-weight: 700;'></span>
                  </div>
                `,
        },
        htmlMarker2 = {
          content: `
                  <div style='width: 48px; height: 48px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.20);
                  display: flex; align-items: center; justify-content: center'>
                  <div style='width: 32px; height: 32px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.70);
                  display: flex; align-items: center; justify-content: center'>
                    <span style='color: white; font-size: 14px; font-weight: 700;'></span>
                  </div>
                  </div>
                `,
        },
        htmlMarker3 = {
          content: `
                  <div style='width: 60px; height: 60px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.20);
                  display: flex; align-items: center; justify-content: center'>
                  <div style='width: 40px; height: 40px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.70);
                  display: flex; align-items: center; justify-content: center'>
                    <span style='color: white; font-size: 14px; font-weight: 700;'></span>
                  </div>
                  </div>
                `,
        },
        htmlMarker4 = {
          content: `
                  <div style='width: 60px; height: 60px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.20);
                  display: flex; align-items: center; justify-content: center'>
                  <div style='width: 40px; height: 40px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.70);
                  display: flex; align-items: center; justify-content: center'>
                    <span style='color: white; font-size: 14px; font-weight: 700;'></span>
                  </div>
                  </div>
                `,
        },
        htmlMarker5 = {
          content: `
                  <div style='width: 60px; height: 60px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.20);
                  display: flex; align-items: center; justify-content: center'>
                  <div style='width: 40px; height: 40px; border-radius: 50%; background-color: rgba(234, 42, 58, 0.70);
                  display: flex; align-items: center; justify-content: center'>
                    <span style='color: white; font-size: 14px; font-weight: 700;'></span>
                  </div>
                  </div>
                `,
        };

      if (classRef.current) {
        classRef.current.setOptions('markers', [...markers]);
      } else {
        classRef.current = new MarkerClustering({
          minClusterSize: 2,
          // maxZoom: 24,
          maxZoom: 13,
          map: mapRef.current,
          markers: [...markers],
          disableClickZoom: false,
          gridSize: 200,
          icons: [
            htmlMarker1,
            htmlMarker2,
            htmlMarker3,
            htmlMarker4,
            htmlMarker5,
          ],
          indexGenerator: [9, 100, 300, 500, 1000],
          stylingFunction: function (clusterMarker, count) {
            clusterMarker.getElement().querySelector('span').textContent =
              count;
          },
        });
      }
    }

    updateMarkers(markers);

    return () => {
      if (classRef.current) {
        classRef.current.onRemove();
      }
    };
  }, [company, likeList]);

  useEffect(() => {
    // 지도 초기화 및 설정
    const initMap = () => {
      const { naver } = window;
      const mapOptions = {
        center: new naver.maps.LatLng(37.534515, 126.989693),
        zoomControl: false,
        zoom: 18,
      };
      mapRef.current = new naver.maps.Map('map', mapOptions);

      callMessage();
    };
    initMap();
  }, []);

  return <div id='map' style={{ width: '100vw', height: '100vh' }}></div>;
};

export default Map;
