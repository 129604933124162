import React from 'react';

import './App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RecoilRoot } from 'recoil';
import AppInenr from './AppInner';

const App = () => {
  return (
    <>
      <RecoilRoot>
        <AppInenr />
      </RecoilRoot>
    </>
  );
};

export default App;
