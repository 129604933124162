/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../components/layout/Container';
import Header from '../../components/ui/Text/Header/Header';
import Button from '../../components/ui/buttons/Button';
import TextField from '../../components/ui/fields/TextField';
import CardImage from '../../components/ui/card/CardImage';
import CardText from '../../components/ui/card/CardText';

import VerticalSpace from '../../components/layout/VerticalSpace';
import HorizontalSpace from '../../components/layout/HorizontalSpace';

import { useNavigate, useParams } from 'react-router-dom';

import React, { useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { reviewInputState } from '../../store/atoms';

const ContactForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();
  const setReviseInput = useSetRecoilState(reviewInputState);

  const { storeId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const nextStep = () => {
    if (phoneNumber.length !== 11) {
      inputRef.current?.focus();
    } else {
      setReviseInput((prev) => ({ ...prev, phone: phoneNumber }));
      navigate(`/receipt/guide/${storeId}`);
    }
  };

  return (
    <>
      <Container
        footerElement={
          phoneNumber.length === 11 ? (
            <Button label='확인' onClick={nextStep} />
          ) : (
            <></>
          )
        }
      >
        <div
          css={css`
            flex: 1;
            padding: 0px 24px;
          `}
        >
          <VerticalSpace size={44} />
          <Header
            title='상품을 받으실 연락처를 알려주세요.'
            description='영수증 리뷰하면 상품권을 보내드려요.'
          />
          <VerticalSpace size={16} />
          <TextField
            autoFocus
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            ref={inputRef}
            width='100%'
            type='tel'
            maxLength={11}
            placeholder='휴대폰 번호를 입력해주세요.'
          />
          <VerticalSpace size={32} />
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <CardImage URL='https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/qr%2FcardList%2Fnaver.png?alt=media&token=4da7f6ec-ec16-45f3-98dc-b4c944fa9222' />
            <HorizontalSpace size={24} />
            <CardText
              companyName='네이버 페이'
              benefits='모바일 상품권 1천원권'
              amount='1,000원'
              size='medium'
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ContactForm;
