import axios from 'axios';
import { useState, useEffect } from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';

const StoreVerificationRoute = () => {
  const [verify, setVerify] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const { storeId } = useParams();

  useEffect(() => {
    const requestVerify = async () => {
      try {
        const {
          data: { message },
        } = await axios.get<{
          result: boolean;
          message?: string;
        }>(
          `https://api.supermembers.co.kr/receipt/review/check?storeId=${storeId}`
        );

        setVerify(message !== 'INVALID_STOREID');
      } catch (err) {
      } finally {
        setIsLoaded(false);
      }
    };

    requestVerify();
  }, [storeId]);

  return isLoaded ? (
    <></>
  ) : verify ? (
    <Outlet />
  ) : (
    <Navigate to='/receipt/invalid-store' />
  );
};

export default StoreVerificationRoute;
