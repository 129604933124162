/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '../../color';
import Typos from '../../typography';

interface Props {
  companyName: string;
  benefits: string;
  amount: string;
  size: 'small' | 'medium';
}

const TEXT_SIZE_MAP = {
  small: {
    companyName: Typos.Caption,
    benefits: Typos.Body5,
    amount: Typos.Body4,
  },
  medium: {
    companyName: Typos.Body5,
    benefits: Typos.Body3,
    amount: Typos.Body1,
  },
};

const CardText = ({ companyName, benefits, amount, size }: Props) => {
  return (
    <div>
      <p
        css={css`
          ${TEXT_SIZE_MAP[size]['companyName']};
          color: ${Color.default1};
          margin-bottom: 2px;
        `}
      >
        {companyName}
      </p>
      <p
        css={css`
          ${TEXT_SIZE_MAP[size]['benefits']};
          color: ${Color.default3};
        `}
      >
        {benefits}
      </p>
      <p
        css={css`
          ${TEXT_SIZE_MAP[size]['amount']};
          color: ${Color.default2};
        `}
      >
        {amount}
      </p>
    </div>
  );
};

export default CardText;
