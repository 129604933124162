/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Step5Icon from '../../asset/landing/Step5.png';
import useWindowWidth from '../../hooks/useWindowWidth';
import WebTypos from '../typography/Web';

const Step5 = () => {
  const windowWidth = useWindowWidth();

  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 150px;

        @media (max-width: 1320px) {
          flex-direction: column;
          gap: 0px;
        }
      `}
    >
      <div
        css={css`
          width: 400px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-left: 110px;

          @media (max-width: 1320px) {
            gap: 12px;
            margin-left: 0px;
            text-align: center;
            width: max-content;
          }
        `}
      >
        {windowWidth > 1320 && (
          <img
            src={Step5Icon}
            alt='Step5Icon'
            css={css`
              height: 72px;
              width: fit-content;
            `}
          />
        )}
        <p
          css={css`
            ${WebTypos.Headline2};
            @media (max-width: 1320px) {
              ${WebTypos.Headline4};
            }
          `}
        >
          등급이 올라가면 <br />
          혜택도 UP!
        </p>
        <p
          css={css`
            ${WebTypos.Body3};
            @media (max-width: 1320px) {
              ${WebTypos.Body6};
              margin-bottom: 40px;
            }
          `}
        >
          슈퍼멤버스에는 등급별로 블랙 • 레드 • 옐로우
          <br /> 세가지 랭크가 있습니다. 등급이 올라가면 <br />
          더욱 큰 혜택을 받으실 수 있습니다.
        </p>
      </div>
      <div css={css``}>
        <video
          autoPlay
          muted
          loop
          css={css`
            width: 620px;

            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        >
          <source
            src={`${process.env.PUBLIC_URL}/videos/level_up_desktop_x3.mp4`}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Step5;
