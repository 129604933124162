/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Step6Icon from '../../asset/landing/Step6.png';
import Step6Img from '../../asset/landing/step6Img.png';
import useWindowWidth from '../../hooks/useWindowWidth';
import WebTypos from '../typography/Web';

const Step6 = () => {
  const windowWidth = useWindowWidth();

  return (
    <div
      css={css`
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 150px;

        @media (max-width: 1320px) {
          gap: 0px;
          flex-direction: column-reverse;
        }
      `}
    >
      <div css={css``}>
        <img
          src={Step6Img}
          alt='Step6Img'
          css={css`
            width: 620px;

            @media (max-width: 1320px) {
              width: 335px;
            }
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 400px;
          margin-right: 110px;

          @media (max-width: 1320px) {
            gap: 12px;
            text-align: center;
            margin-right: 0px;
            width: max-content;
          }
        `}
      >
        {windowWidth > 1320 && (
          <img
            src={Step6Icon}
            alt='Step6Icon'
            css={css`
              height: 72px;
              width: fit-content;
            `}
          />
        )}
        <p
          css={css`
            ${WebTypos.Headline2};
            @media (max-width: 1320px) {
              ${WebTypos.Headline4};
            }
          `}
        >
          AI로 편리하게,
          <br /> 블로그 포스팅 어시스턴트
        </p>
        <p
          css={css`
            ${WebTypos.Body3};
            @media (max-width: 1320px) {
              ${WebTypos.Body6};
              margin-bottom: 40px;
            }
          `}
        >
          포스팅 주제 • 카테고리 • 키워드를 설정하여,
          <br /> 블로그 포스팅 글을 작성할 때에 도움을 받으실 수 있습니다.
        </p>
      </div>
    </div>
  );
};

export default Step6;
