import React, { FC } from 'react';

import './LandingBanner.scss';

interface Props {
  service?: boolean;
}

const LandingBanner: FC<Props> = ({ service }) => {
  return (
    <div className={`banner flex ${service ? 'full' : ''}`}>
      <h2 className='title flex'>리뷰 작성 안내</h2>
      <div className='flex row'>
        <a
          id='banner'
          className='inversed'
          href='https://s3.ap-northeast-2.amazonaws.com/supermembers.app/frontend/banner-small.png'
          download='supermembers-banner.jpg'
          target='_blank'
          rel='noopener noreferrer'
        >
          스폰서 배너 다운로드<i className='material-icons'>chevron_right</i>
        </a>
        <a
          href='https://blog.naver.com/supermembers/221403129447'
          target='_blank'
          rel='noopener noreferrer'
        >
          네이버 블로그 회원<i className='material-icons'>chevron_right</i>
        </a>
      </div>

      <h2 className='title flex'>이용 관련 안내</h2>
      <div className='flex row'>
        <a
          href='https://blog.naver.com/supermembers/221406297120'
          className='inversed'
          target='_blank'
          rel='noopener noreferrer'
        >
          서비스 이용 안내<i className='material-icons'>chevron_right</i>
        </a>
        <a
          href='http://pf.kakao.com/_Jgyxid'
          target='_blank'
          rel='noopener noreferrer'
        >
          고객센터 카톡 문의<i className='material-icons'>chevron_right</i>
        </a>
      </div>
    </div>
  );
};

export default LandingBanner;
