/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Typos = {
  Headline: css`
    font-size: 23px;
    font-family: 'Pretendard-Bold';
    font-weight: 700;
    line-height: 150%;
  `,
  Subline1: css`
    font-size: 17px;
    font-family: 'Pretendard-SemiBold';
    font-weight: 600;
    line-height: 150%;
  `,
  Subline2: css`
    font-size: 17px;
    font-family: 'Pretendard-Regular';
    line-height: 150%;
  `,
  Body1: css`
    font-size: 16px;
    font-family: 'Pretendard-Bold';
    font-weight: 700;
    line-height: 150%;
  `,
  Body2: css`
    font-size: 16px;
    font-family: 'Pretendard-Medium';
    font-weight: 500;
    line-height: 150%;
  `,
  Body3: css`
    font-size: 16px;
    font-family: 'Pretendard-Regular';
    line-height: 150%;
  `,
  Body4: css`
    font-size: 14px;
    font-family: 'Pretendard-Bold';
    font-weight: 700;
    line-height: 150%;
  `,
  Body5: css`
    font-size: 14px;
    font-family: 'Pretendard-Regular';
    line-height: 150%;
  `,
  Caption: css`
    font-size: 12px;
    font-family: 'Pretendard-Regular';
    line-height: 150%;
  `,
};

export default Typos;
