import { atom } from 'recoil';

export const reviewInputState = atom({
  key: 'reviewInputState',
  default: {
    phone: '',
    emoji: '',
  },
});

export const isLoadingState = atom({
  key: 'isLoadingState',
  default: false,
});
