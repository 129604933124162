/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Color from '../color';
import Typos from '../typography';

export interface AlertMessageProps {
  showAlert: (message: string) => void;
}

const Alert = forwardRef<AlertMessageProps, any>((props, ref) => {
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const alertStyle = css`
    height: 44px;
    border-radius: 8px;
    color: ${Color.inversed};
    background-color: ${Color.primary1};
    padding: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: calc(100% - 48px);
    left: 50%;
    transform: translateX(-50%);

    bottom: 88px;

    opacity: ${isVisible ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
    pointer-events: none;
  `;

  const showAlert = (text: string) => {
    setMessage(text);
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  };

  useImperativeHandle(ref, () => ({
    showAlert,
  }));

  return (
    <div css={alertStyle}>
      <span
        className='mdi mdi-alert-circle-outline'
        css={css`
          font-size: 24px;
          margin-right: 8px;
        `}
      ></span>
      <p
        css={css`
          ${Typos.Body2};
          color: ${Color.inversed};
        `}
      >
        {message}
      </p>
    </div>
  );
});

export default Alert;
