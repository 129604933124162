/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../components/layout/Container';
import Header from '../../components/ui/Text/Header/Header';
import Button from '../../components/ui/buttons/Button';
import TextField from '../../components/ui/fields/TextField';
import VerticalSpace from '../../components/layout/VerticalSpace';
import HorizontalSpace from '../../components/layout/HorizontalSpace';

import { useNavigate, useParams } from 'react-router-dom';

import React, { useEffect, useRef, useState } from 'react';
import EmojiField from '../../components/ui/fields/EmojiField';
import GhostButton from '../../components/ui/buttons/GhostButton';
import { useRecoilValue } from 'recoil';
import { isLoadingState, reviewInputState } from '../../store/atoms';
import Carousel from '../../components/ui/Carousel';
import Alert, { AlertMessageProps } from '../../components/ui/Alert';
import CombineStep from '../../asset/step-2+3.png';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import ToastMessage, {
  ToastMessageProps,
} from '../../components/ui/ToastMessage';

const ReviewGuide = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);

  const navigate = useNavigate();
  const { storeId } = useParams();

  const [reviewURL, setReviewURL] = useState('');
  const reviewInput = useRecoilValue(reviewInputState);
  const toastRef = useRef<ToastMessageProps>(null);
  const alertRef = useRef<AlertMessageProps>(null);

  const copyEmoji = () => {
    toastRef.current?.showToast('이모티콘 복사 완료');
    navigator.clipboard.writeText(reviewInput.emoji);
  };

  const registerReview = async () => {
    try {
      const response = await axios.post(
        'https://api.supermembers.co.kr/receipt/review',
        {
          storeId,
          goodsNo: 1,
          contacts: reviewInput.phone,
          receiptReview: reviewURL,
          emoji: reviewInput.emoji,
          status: 1,
        }
      );
      setIsLoading(false);
      navigate(`/receipt/result/${storeId}`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!reviewURL.includes('https://naver.me/')) {
      return;
    }

    setIsLoading(true);

    try {
      const {
        data: { result, message },
      } = await axios.get<{
        result: boolean;
        message?:
          | 'INVALID_EMOJI'
          | 'NOT_MATCH_EMOJI'
          | 'TOO_OLD_REVIEW'
          | 'NOT_MATCH_STORE'
          | 'INVALID_URL';
      }>(
        `https://api.supermembers.co.kr/receipt/review/check?storeId=${storeId}&url=${reviewURL}&emoji=${reviewInput.emoji}`
      );

      if (result) {
        registerReview();
      } else {
        const errorMessage = (() => {
          switch (message) {
            case 'INVALID_EMOJI':
            case 'NOT_MATCH_EMOJI': {
              return '리뷰에서 이모티콘을 확인해주세요';
            }

            case 'TOO_OLD_REVIEW':
            case 'NOT_MATCH_STORE':
            case 'INVALID_URL': {
              return '올바른 링크를 넣어주세요';
            }

            default: {
              return '다시 시도해주세요';
            }
          }
        })();

        alertRef.current?.showAlert(errorMessage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (reviewURL.includes('https://naver.me/')) {
      onSubmit();
    }
  }, [reviewURL]);

  return (
    <Container>
      <div
        css={css`
          flex: 1;
          padding: 0px 24px;
        `}
      >
        <VerticalSpace size={44} />
        <Header title='포토리뷰 링크를 입력해주세요.' />
        <VerticalSpace size={16} />
        <TextField
          autoFocus
          width='100%'
          type='text'
          placeholder='플레이스 리뷰 URL을 넣어주세요.'
          onChange={(event) => {
            setReviewURL(event.target.value);
          }}
        />
        <VerticalSpace size={16} />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <EmojiField emoji={reviewInput.emoji} />
          <HorizontalSpace size={12} />
          <GhostButton label='복사' onClick={copyEmoji} />
        </div>
        <VerticalSpace size={16} />
        <Carousel>
          <Carousel.CarouselItem>
            <img
              alt={`step`}
              src={CombineStep}
              css={css`
                width: 100%;
                height: 100%;
                object-fit: cover;
              `}
            />
          </Carousel.CarouselItem>
        </Carousel>
      </div>
      <Alert ref={alertRef} />
      <ToastMessage ref={toastRef} />
    </Container>
  );
};

export default ReviewGuide;
