/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface Props {
  size: number;
}

const HorizontalSpace = ({ size }: Props) => {
  return (
    <div
      css={css`
        padding-left: ${size}px;
      `}
    ></div>
  );
};

export default HorizontalSpace;
