/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, forwardRef } from 'react';
import Color from '../../color';
import Typos from '../../typography';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  margin?: string;
}

const TextField = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <input
      ref={ref}
      onBlur={() => {
        setHasFocus(false);
      }}
      onFocus={() => {
        setHasFocus(true);
      }}
      {...props}
      css={css`
        background-color: ${Color.inversed};
        height: 52px;
        border-radius: 8px;
        border: 2px solid ${hasFocus ? Color.primary1 : Color.disabled1};
        ${Typos.Subline1};
        padding: 13px 20px;

        width: ${props.width};
        margin: ${props.margin};

        outline: none;

        ::placeholder {
          color: ${Color.disabled3};
        }
      `}
    />
  );
});

export default TextField;
