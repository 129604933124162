import React from 'react';

class Privacy extends React.PureComponent {
  private iframe: HTMLIFrameElement | null = null;
  public render() {
    return (
      <div className='content-wrapper contents help-contents'>
        <iframe
          ref={(comp) => (this.iframe = comp)}
          src='/Privacy.html'
          title='슈퍼멤버스 개인정보 취급방침'
          frameBorder='0'
          width='100%'
        />
      </div>
    );
  }
  public componentDidMount() {
    window.setTimeout(() => {
      this &&
        this.iframe &&
        (this.iframe.height =
          ((((this.iframe.contentWindow || {}).document || {}).body || {})
            .scrollHeight || 0) +
          100 +
          'px');
    }, 200);
  }
}

export default Privacy;
