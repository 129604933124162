import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const { pathname } = useLocation();

  if (pathname.includes('/receipt') || pathname.includes('/map')) {
    return null;
  }

  return (
    <header className='main-header'>
      <div className='content-wrapper flex'>
        <Link to='/'>
          <img
            src={
              'https://s3.ap-northeast-2.amazonaws.com/supermembers.app/logo.svg'
            }
            alt='supermembers logo'
          />
        </Link>
        <nav>
          <a href='https://www.supermembers.co.kr'>사장님 사이트</a>
          <NavLink to='/rank'>랭킹조회</NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;
