/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const WebTypos = {
  Headline: css`
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 56px */
    font-family: 'Pretendard-Bold';
  `,
  Headline2: css`
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 42px */
    font-family: 'Pretendard-Bold';
  `,
  Headline3: css`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 39.2px */
    font-family: 'Pretendard-Bold';
  `,
  Headline4: css`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    font-family: 'Pretendard-Bold';
  `,
  Headline5: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 28.8px */
    font-family: 'Pretendard-Bold';
  `,
  Body1: css`
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    font-family: 'Pretendard-Regular';
  `,
  Body2: css`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 32.4px */
    font-family: 'Pretendard-Bold';
  `,
  Body3: css`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 32.4px */
    font-family: 'Pretendard-Regular';
  `,
  Body4: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    font-family: 'Pretendard-Regular';
  `,
  Body5: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 25.2px */
    font-family: 'Pretendard-Bold';
  `,
  Body6: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 25.2px */
    font-family: 'Pretendard-Regular';
  `,
  Body7: css`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    font-family: 'Pretendard-Regular';
  `,
};

export default WebTypos;
