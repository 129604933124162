/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../components/layout/Container';
import DescriptionText from '../../components/ui/Text/DescriptionText/DescriptionText';
import Header from '../../components/ui/Text/Header/Header';
import Button from '../../components/ui/buttons/Button';
import VerticalSpace from '../../components/layout/VerticalSpace';
import { useNavigate, useParams } from 'react-router-dom';
import OnBoardingIMG from '../../asset/onBoarding.png';

const Onboarding = () => {
  const navigate = useNavigate();
  const { storeId } = useParams();

  return (
    <Container
      footerElement={
        <Button
          label='1분만에 리뷰 인증하기'
          isRounded
          onClick={() => {
            navigate(`/receipt/contact/${storeId}`);
          }}
        />
      }
      footerPadding='0px 24px'
    >
      <div
        css={css`
          flex: 1;
          padding: 0px 24px;
          display: flex;
          flex-direction: column;
        `}
      >
        <VerticalSpace size={44} />
        <Header
          title={`1분만에 영수증 리뷰 남기고
        1,000원을 환급 받을 수 있다고?`}
          description='지금 바로, 영수증 리뷰를 인증해보세요.'
        />
        <VerticalSpace size={16} />
        <div
          css={css`
            width: 100%;
            height: 300px;
          `}
        >
          <img
            alt='OnBoardingIMG'
            src={OnBoardingIMG}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <VerticalSpace size={66} />
        <DescriptionText text='24시간 내 방문한 영수증만 참여할 수 있어요' />
        <VerticalSpace size={20} />
      </div>
    </Container>
  );
};

export default Onboarding;
